<template>
  <v-app class="bg-black">
    <v-app-bar elevation="3">
      <!-- v-if="roles.includes('admin'||'super-admin')" -->
      <v-btn
        id="menu-activator"

        icon="mdi-menu"
      />

      <v-menu activator="#menu-activator">
        <v-list>
          <v-list-item>
            <v-btn
              variant="text"
              to="/admin/products"
            >
              Products
            </v-btn>
          </v-list-item>
          <!-- <v-list-item>
            <v-btn
              variant="text"
              to="/admin/orders"
            >
              Orders
            </v-btn>
          </v-list-item> -->
          <v-list-item>
            <v-btn
              variant="text"
              to="/admin/webhooks/printify"
            >
              Webhooks
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              variant="text"
              to="/admin/cases/"
            >
              Cases
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-app-bar-title>
        <NuxtLink to="/admin">
          <h3 class="">
            SICO Administrators
          </h3>
        </NuxtLink>
      </v-app-bar-title> 
      <v-spacer />
      <NuxtLink to="/admin">
        <v-btn
          icon="mdi-book"
        />
      </NuxtLink>
      <NuxtLink to="/account">
        <v-btn
          icon="mdi-account"
        />
      </NuxtLink>
      <NuxtLink to="/">
        <v-btn
          icon="mdi-home"
        />
      </NuxtLink>
    </v-app-bar>
    <v-main>
      <v-container
        class="w-100 bg-white h-full"
      >
        <!-- v-if="loaded" -->
        <div
          class="mx-10 <sm:(m-0 mt-10)"
        >
          <slot />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>